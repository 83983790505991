
import React, { useEffect, useState } from 'react'
import { API } from '../utils/API.js'
import App from './App.js'
import { Button, Flex, Input, Spin } from 'antd'

export default function Login(props) {

	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [token, setToken] = useState(localStorage.getItem('token'))
	const [permissions, setPermissions] = useState()

	const doLogin = async () => {
		setLoading(true)
		
		const token = await API.login(login, password)

		if (token) {
			localStorage.setItem('token', token)
			setToken(token)
		}

		setLoading(false)
	}

	useEffect(() => {

		if (token) {
			setLoading(true)
			API.getPermissions().then(permissions => {
				setPermissions(permissions)
				setLoading(false)
			})
		} else {
			setPermissions(null)
		}
		
	}, [token])

	return <>
		{!permissions &&
			<Spin spinning={loading} size='large' tip='Loading...'>
				<Flex vertical gap='small' justify='center' align='stretch' style={{height: '100vh', width: '200px', margin: 'auto'}}>
					<Input size='large' type="text" value={login} onChange={e=>setLogin(e.target.value)} placeholder='Login'/>
					<Input size='large' type="password" value={password} onChange={e=>setPassword(e.target.value)} placeholder='Password'/>
					<Button size='large' onClick={doLogin}>Login</Button>
				</Flex>
			</Spin>
		}
		{permissions && <App permissions={permissions} />}
	</>
}