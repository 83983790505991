import React from 'react'
import './Gem.css'
import Heart from './heart.png'
import NoFight from './noFight.png'
import String from '../string/String.js'

export default function Gem(props) {

	let action = props.action ? <span className={`icon icon-${props.action}`}></span> : null
	let heart = props.heart ? <img src={Heart} className="gem-heart" alt="" /> : null
	const noFight = props.noFight ? <img src={NoFight} className="gem-noFight" alt="" /> : null

	return (
		<div className={`gem icon-white gem-color-${props.color? props.color : 'gray'}`}>
			{action}
			{props.command && <String className="gem-command">{props.command}</String>}
			{heart}
			{noFight}
		</div>
	)
}