import React, {useContext, useState, useEffect} from 'react'
import Map from './map/Map.js'
import Narrative from './narrative/Narrative.js'
import Event from './event/Event.js'
import Item from './item/Item.js'
import Character from './character/Character.js'
import Cache from './cache/Cache.js'
import KarmaRecto from './karma_recto/KarmaRecto.js'
import KarmaVerso from './karma_verso/KarmaVerso.js'
import { registerHelper } from '../../utils/Helper.js'
import './Card.css'
import {RenderModeContext, RenderMode as RenderConst, DevContext, LangContext} from 'utils/Context.js'
import {Form, FormSelect} from 'components/form/FormItems.js'
import { Button, Divider, Drawer, Flex, Modal, Select, Space, Tabs, Tooltip, message } from 'antd'
import {
  ScissorOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { BleedContext, Role, RoleContext } from '../../utils/Context.js'
import { SaveOutlined } from '@ant-design/icons'
import { FormDivider } from '../form/FormItems.js'

const getContent = (props) => {
	const data = props.data
	
	let content = null

	switch (data.type) {
		case 'map':
			content = <Map {...props} />
		break
		case 'narrative':
			content = <Narrative {...props} />
		break
		case 'event':
			content = <Event {...props} />
		break
		case 'object': 
			content = <Item {...props} />
		break
		case 'character':
			content = <Character {...props} />
		break
		case 'cache':
			content = <Cache {...props} />
		break 
		case 'karma-verso':
			content = <KarmaVerso {...props} />
		break
		case 'karma-recto':
			content = <KarmaRecto {...props} />
		break
		default:
			//throw "not implemented"
	}

	return content
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error: error };
  }

  render() {
    if (this.state.error) {
      return <p className="editor-error">Error in rendering</p>;
    }

    return this.props.children; 
  }
}

const RenderMode = ({data, onChange}) => {
	
	const lang = useContext(LangContext)
	const bleed = useContext(BleedContext) 
	const devContext = useContext(DevContext)
	const role = useContext(RoleContext)

	// needed to fix bug of translation in preview
	registerHelper(lang)

	const dataToUse = devContext && data && data.dev ? data.dev : data

	const [edit, setEdit] = useState(false)
	const [editorValue, setEditorValue] = useState(dataToUse)
	const [valueHasChanged, setValueHasChanged] = useState(false)

	// needed because the editor value was not taking in account updates when switching from a branch to another one
	useEffect(() => {
		setEditorValue(data)
		// eslint-disable-next-line
	}, [data])

	if (!dataToUse) return null

	const content = getContent({onChange: onChange, data: dataToUse})

	const doSave = () => {
		setEdit(false)
		onChange(editorValue)
		setValueHasChanged(false)
	}

	const doCancel = () => {
		setEditorValue(data)
		setEdit(false)
		setValueHasChanged(false)
	}

	const updateValue = v => {
		setEditorValue(v)
		setValueHasChanged(true)
	}

	const closeDrawer = () => {

		if (valueHasChanged) {
			Modal.confirm({
				title: 'Close without saving?',
				content: 'You have unsaved changes. Do you confirm that you want to close this panel and lost the changes?',
				onOk: () => {
					doCancel()
				}
			});
		} else setEdit(false)
	}

	// destroy on close needed to force refresh the form when reopening
	return <>
		<Drawer destroyOnClose={true} open={edit} width={390 + bleed*5} onClose={closeDrawer} closable={false}  push={{distance: 540}} className={ `build bleed-${bleed}`}>

			<Drawer width={540} open={edit} mask={false} closable={false} footer={
				(role.canFullEdit || (role.canTranslate && role.mainLang !== lang)) && <Flex gap="small">
					<Button block size="large" icon={<SaveOutlined />} type="primary" onClick={doSave}>Save</Button>
					<Button size="large" type="text" onClick={doCancel}>Cancel</Button>
				</Flex>
			}>
				<RenderModeContext.Provider value={RenderConst.editMode}>
					<Card data={editorValue} onChange={(value) => updateValue(value)}/> 
				</RenderModeContext.Provider>
			</Drawer>

			<RoleContext.Provider value={{...Role.viewer, mainLang: role.mainLang}}>
				<DevContext.Provider value={false}>
						<Divider orientation="left">ORIGINAL {role.mainLang}</Divider>
						<ErrorBoundary key={Math.random()}>
							<LangContext.Provider value={role.mainLang}>
								<Card data={editorValue} /> 
							</LangContext.Provider>
						</ErrorBoundary>
				</DevContext.Provider>
				
				{lang !== role.mainLang &&
					<DevContext.Provider value={false}>
						<Divider orientation="left">ORIGINAL {lang}</Divider>
						<ErrorBoundary key={Math.random()}>
							<LangContext.Provider value={lang}>
								<Card data={editorValue} /> 
							</LangContext.Provider>
						</ErrorBoundary>
					</DevContext.Provider>
				}

				{role.canFullEdit && editorValue.dev &&			
					<DevContext.Provider value={true}>
						<Divider orientation="left">DEV {role.mainLang}</Divider>
							<ErrorBoundary key={Math.random()}>
								<LangContext.Provider value={role.mainLang}>
										<Card data={editorValue} /> 
								</LangContext.Provider>
							</ErrorBoundary>
					</DevContext.Provider>
				}

				{role.canFullEdit && lang !== role.mainLang && editorValue.dev &&	
					<DevContext.Provider value={true}>
						<Divider orientation="left">DEV {lang}</Divider>
							<ErrorBoundary key={Math.random()}>
								<LangContext.Provider value={lang}>
										<Card data={editorValue} /> 
								</LangContext.Provider>
							</ErrorBoundary>
					</DevContext.Provider>
				}
				
			</RoleContext.Provider>
		</Drawer>

		<span className="editor-content" onClick={(e) => role.canOpenEditor && setEdit(true)}>
			<div className={`card ${data.type} ${data.subtype?data.subtype:''} ${lang}`}>
				<div className={data.dev ? 'card-info card-info-dev' : 'card-info'}>
						{data.id}{data.replace && ` > ${data.replace}`} 
				</div>
				<div className="content">
					{content}
				</div>
				<div className="card-version"></div>
			</div>
		</span>
	</>		
}

const EditMode = ({data, onChange}) => {

	const role = useContext(RoleContext)

	const copy = () => {
		localStorage.setItem('copy-card', JSON.stringify(data))
		message.success('Card copied!')
	}

	const paste = () => {
		let copiedData = JSON.parse(localStorage.getItem('copy-card'))

		if (!copiedData) return

		onChange({...copiedData, id: data.id})
	}

	const sendToDev = () => {

		// deep copy
		const dev = JSON.parse(JSON.stringify(data))
		delete dev.dev
		delete dev.print

		onChange({...data, dev: dev})
	}

	const removeDev = () => {
		const dev = {...data}
		delete dev.dev
		onChange({...dev})
	}


	const sendDevToOriginal = () => {

		const dev = data.dev

		onChange(dev)
	}

	/* eslint-disable jsx-a11y/accessible-emoji*/
	return <>
		{role.canFullEdit && <>
			<Space.Compact>
				<Tooltip title="Copy">
					<Button icon={<ScissorOutlined />} onClick={copy} />
				</Tooltip>
				<Tooltip title="Paste">
					<Button icon={<CopyOutlined />} onClick={paste} />
				</Tooltip>
			</Space.Compact>
			<Tabs items={[
				{
					key: 'original',
					label: 'Original',
					children: <>
						<Form data={data} onChange={onChange}>
							<FormSelect label="Card type" name="type">
								<Select.Option value="narrative">Narrative</Select.Option>
								<Select.Option value="map">Map</Select.Option>
								<Select.Option value="object">Item</Select.Option>
								<Select.Option value="event">Event</Select.Option>
								<Select.Option value="karma-recto">Karma recto</Select.Option>
								<Select.Option value="karma-verso">Karma verso</Select.Option>
								<Select.Option value="cache">Cache</Select.Option>
								<Select.Option value="character">Character</Select.Option>
							</FormSelect>
							<FormDivider />
							{getContent({data, onChange})}
						</Form>
					</>
				},
				{
					key: 'dev',
					label: 'Dev',
					children: <>
						<div className='card-editor-buttons'>
							<Button onClick={sendToDev}>Copy Original to Dev</Button>
							{data.dev && <Button onClick={removeDev}>❌ dev</Button>}
							{data.dev && <Button onClick={sendDevToOriginal}>Send Dev to Original</Button>}
						</div>
						{data.dev && <>
							<Form data={data.dev} onChange={v => {onChange({...data, dev: v})}}>
								<FormSelect label="Card type" name="type">
									<Select.Option value="narrative">Narrative</Select.Option>
									<Select.Option value="map">Map</Select.Option>
									<Select.Option value="object">Item</Select.Option>
									<Select.Option value="event">Event</Select.Option>
									<Select.Option value="karma-recto">Karma recto</Select.Option>
									<Select.Option value="karma-verso">Karma verso</Select.Option>
									<Select.Option value="cache">Cache</Select.Option>
									<Select.Option value="character">Character</Select.Option>
								</FormSelect>
								<Divider />
								{getContent({data: data.dev, onChange: v => onChange({...data, dev: v})})}
							</Form>
						</>}
					</>
				}
			]} />
		</>
	}
	{!role.canFullEdit && <>
		<Form data={data} onChange={onChange}>
			<FormSelect label="Card type" name="type">
				<Select.Option value="narrative">Narrative</Select.Option>
				<Select.Option value="map">Map</Select.Option>
				<Select.Option value="object">Item</Select.Option>
				<Select.Option value="event">Event</Select.Option>
				<Select.Option value="karma-recto">Karma recto</Select.Option>
				<Select.Option value="karma-verso">Karma verso</Select.Option>
				<Select.Option value="cache">Cache</Select.Option>
				<Select.Option value="character">Character</Select.Option>
			</FormSelect>
			<FormDivider />
			{getContent({data, onChange})}
		</Form>
	</>}
	</>
}

export default function Card(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
