import React, {useContext} from 'react'
import './Event.css'
import {FormRichText} from 'components/form/FormItems.js'
import String from 'components/string/String.js'
import {RenderModeContext, LangContext, RoleContext} from 'utils/Context.js'
import Timeline from '../narrative/timeline/Timeline.js'
import { getStringValue } from '../../string/String.js'

const RenderMode = ({data}) => {

	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	return <>
			<div className="event-content">
				<div className="event-story">
					<String>{data.body}</String>
				</div>
			</div>

			<Timeline data={{
				body: getStringValue(data.effect, lang, role.mainLang) ? data.effect : '<br>((flip))',
				anchors: {W: 'left', 'E': 'left'}
			}} />
	</>
}

const EditMode = ({data}) => <>
	<FormRichText label="Event" name="body" />
	<FormRichText label="Effect" name="effect" />
</>

export default function Event(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}