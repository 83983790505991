import React from 'react'
import { IKImage } from 'imagekitio-react'

export default function Print({language, file, cards, dev}) {	

	return <div className="layout-container">
		{
			cards.map(c => {
				const id = c.id
				let cardPrint = dev && c.dev ? dev : c

				if (cardPrint.print && cardPrint.print[language]) {
					return <IKImage key={id} 
						path={`/print/${language}/${file}/${cardPrint.print[language]}`}
						transformation={[{orig: true}]}
					/>
				} else {
					return <div class="error">This image should be rendered again. Ask Jerome to do so.</div>
				}
			})
		}
	</div>
}
