import React, {useContext} from 'react'
import './Item.css'
import Anchors from 'components/anchors/Anchors.js'
import Heart from 'components/gem/heart.png'
import {FormText, FormRichText, FormCheckbox, FormSelect, FormItems, FormImage} from 'components/form/FormItems.js'
import String, {getStringValue} from 'components/string/String.js'
import {RenderModeContext, LangContext, RoleContext} from 'utils/Context.js'
import Image from 'components/image/Image.js'
import { Flex, Form, Select, Switch } from 'antd'

const RenderMode = ({data}) => {

	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	const cost = (data.cost) ?  (
		<div className="object-cost">
			{data.cost} <span className="icon icon-material"></span>
		</div>
		) : null

	const effect = getStringValue(data.effect, lang, role.mainLang) ? (
		<div className="object-effect icon-white"><String>{data.effect}</String></div>
	) : null

	const camp = getStringValue(data.camp, lang, role.mainLang) ? (
		<div className="object-footer icon-white">
			<span className="icon icon-interact"></span> <String>{data.camp}</String>
		</div>
	) : null
		
	const actions = (data.actions) ? data.actions.map((item, itemIdx) => {
		if (!item || !item.type) return null

		let content = getStringValue(item.effect, lang, role.mainLang) ? (
			<div className="icon-white object-action-content">
				<String>{item.effect}</String>
			</div>
		) : null

		let constraints = item.type.map((c, idx) => (
			(c === "health") ? <img key={idx} src={Heart} className="object-heart" alt="" /> : <span key={idx} className={`icon icon-${c}`}></span>
		))

		return (
			<div key={itemIdx} className={`object-action object-action-${itemIdx} ${camp ? '' : 'object-action-nocamp'}`}>
				{content}
				<div className="object-gem">
					{constraints}
				</div>
			</div>
		)
	}) : null

	const image = <Image className="object-image" name={data.image} />

	return (
		<div>
			<div className="object-body">
				<span className="object-connector"></span>
				{image}
				{effect}
				{actions}
			</div>

			<div className="object-header icon-white">
				<div className="object-name"><String>{data.name}</String></div>
				{cost}
			</div>

			<Anchors data={data.anchors} />
			{camp}
		</div>
	)
}

const ItemAction = ({value, onChange}) => {

	let health = false
	let action = null

	for (let it of value) {
		if (it === 'health') health = true
		else action = it
	}

	const updateHealth = (health) => {
		const result = []
		if (health) result.push('health')
		result.push(action)
		onChange(result)
	}

	const updateAction = (action) => {
		const result = []
		if (health) result.push('health')
		result.push(action)
		onChange(result)
	}

	/* eslint-disable jsx-a11y/accessible-emoji*/
	return <>
		<Flex gap="small" align="center">
			<Switch checked={health} onChange={updateHealth}/> ❤️
			<Select onChange={updateAction} value={action} popupMatchSelectWidth={false}>
				<Select.Option value=""> - </Select.Option>
				<Select.Option value="look">👁️ Look</Select.Option>
				<Select.Option value="interact">✋ Interact</Select.Option>
				<Select.Option value="move">👣 Move</Select.Option>
				<Select.Option value="discuss">👄 Discuss</Select.Option>
			</Select>
		</Flex>
	</>
}

const EditMode = ({data}) => {


	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	return <>
		<Flex gap="small">
			<FormRichText style={{flex: 1}} label="Name" name="name" />
			<FormText style={{flex: "0 0 100px"}} label="Cost" name="cost" />
		</Flex>
		<FormImage label="Image" name="image" />
		<FormSelect label="Type" name= "subtype">
			<Select.Option value=""> - </Select.Option>
			<Select.Option value="bag">Bag</Select.Option>
			<Select.Option value="head">Head</Select.Option>
			<Select.Option value="body">Body</Select.Option>
			<Select.Option value="foot">Foot</Select.Option>
		</FormSelect>
		<FormCheckbox label="Need TTS Token" name="tts" />
		<FormRichText label="Permanent effect" name="effect" />
		<FormRichText label="Action (Interact)" name="camp" />
		<FormItems maxItems="3" label="Actions" name="actions" render={(name, options) => (
			<Flex gap='small'>
				{role.canFullEdit && role.mainLang === lang && <Form.Item style={{flex: "0 0 140px", marginBottom: 0}} label={name===0 ? 'Type' : ''} name={[name, "type"]}>
						<ItemAction />
					</Form.Item>
				}
				<FormRichText style={{flex: 1, marginBottom: 0}} label={name===0 ? 'Effect' : ''} name={[name, "effect"]} />
				{options}
			</Flex>
		)} newItem={{
			type: []
		}} />

		<Anchors name={['anchors']} data={data.anchors} type={'item'}/>
	</>
}

export default function Item(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}