import React, {useContext} from 'react'
import Anchors from 'components/anchors/Anchors.js'
import './Timeline.css'
import {FormRichText, FormCheckbox} from 'components/form/FormItems.js'
import String from 'components/string/String.js'
import {RenderModeContext} from 'utils/Context.js'

const RenderMode = ({data, className}) => <>
	<div className={`timeline icon-white ${className}`}>
		<String>
			{data.body}
		</String>
		{!data.anchorCard && <Anchors data={data.anchors} />}
	</div>
	{data.anchorCard && <Anchors data={data.anchors} />}
</>

const EditMode = ({name, data}) => <>
	<FormRichText label="Timeline" name={[...name, 'body']} />
	<Anchors type={'narrative'} name={[...name, 'anchors']} data={data.anchors} />
	{data.anchors && Object.keys(data.anchors).includes('N') && <FormCheckbox name={[...name, 'anchorCard']} label="Anchors at the top and bottom of the card" /> }
</>


export default function Event(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
