import React, {useContext} from 'react'
import FaceVerso from './karma-verso.png'
import FaceCritical from './karma-verso-critical.png'
import './KarmaVerso.css'
import {FormRichText, FormCheckbox} from 'components/form/FormItems.js'
import String, {getStringValue} from 'components/string/String.js'
import {RenderModeContext, LangContext, RoleContext} from 'utils/Context.js'

const RenderMode = ({data}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	let face = <img className="karma-verso-bg" alt="" src={FaceVerso} />	

	const strValue = data.value && getStringValue(data.value, lang, role.mainLang) !== null ? getStringValue(data.value, lang, role.mainLang) : ''
	
	if (data.value && ( getStringValue(data.value, lang, role.mainLang) === '-2' || getStringValue(data.value, lang, role.mainLang) === '+2')) face = <img className="karma-verso-bg" alt="" src={FaceCritical} />
	//if (data.title) face = <img className="karma-verso-bg" alt="" src={FaceVerso2} />	

	const value = data.value ? (
		<div className={`karma-verso-value icon-white ${strValue.replaceAll(/<[^>]+>/g, '').length > 10 ? 'karma-verso-value-small' : ''} ${data.faction ? 'karma-faction karma-faction-' + data.faction : ''}`}><String>{data.value}</String></div>
	) : null

	const title = (data.title) ? (
		<div className="karma-verso-title icon-white">
			<String>{data.title}</String>
		</div>
	) : null

	const levelup = data.levelup && getStringValue(data.levelup, lang, role.mainLang) ? <>
			<span className='icon icon-levelup'></span>
			<String>{data.levelup}</String>
		</> : null

	return <>
		{face}
		{data.fatal ? <div className='icon-white'><span className='karma-verso-fatal icon icon-warning'></span></div> : null}
		<div className='karma-verso-content'>
			{title}
			{value}

			<div className="karma-verso-levelup icon-white">
				{levelup}
			</div>
		</div>
	</>
}

const EditMode = ({data}) => <>
	<FormRichText label="Title" name="title" />
	<FormRichText label="Value" name="value" />
	<FormRichText label='Upgrade' name="levelup" />
	<FormCheckbox label='Add Skull icon' name="fatal" />
</>

export default function KarmaVerso(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
