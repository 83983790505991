import React, {useContext} from 'react'
import './Anchors.css'
import {FormSelect} from 'components/form/FormItems.js'
import {RenderModeContext, RoleContext} from 'utils/Context.js'
import { Card, Flex, Form, Select } from 'antd'

const RenderMode = ({data}) => {
	if (!data) return null

	return Object.keys(data).map(direction => 
		<div key={direction} className={`card-anchor card-anchor-${data[direction]}-${direction} card-anchor-${direction} card-anchor-${data[direction]}`}></div>
	)
}

const EditMode = ({name, data, type='default'}) => {

	const role = useContext(RoleContext)

	return role.canFullEdit ? <>
		<Form.Item label="Anchors">
			<Card>
				<Flex gap="large" align="center" justify="center">
					{type !=='item' && (
						<FormSelect label="West" name={[...name, "W"]}>
							<Select.Option value=""></Select.Option>
							{type === 'narrative' && <Select.Option value="left">Left Card</Select.Option>}
							{type === 'narrative' && <Select.Option value='right'>Right Card</Select.Option>}
							{type === 'map' && <Select.Option value='car'>Car addon</Select.Option>}
							{type === 'map' && <Select.Option value='car-E'>Car</Select.Option>}
						</FormSelect>
					)}
					
					<Flex vertical gap="small">
						North
						<FormSelect name={[...name, "N"]} style={{marginBottom:0}}>
							<Select.Option value=""></Select.Option>
							{type !== 'map' && <Select.Option value="organic">Organic</Select.Option>}
							{type !== 'map' && <Select.Option value='mechanic'>Mechanic</Select.Option>}
							{type !== 'map' && <Select.Option value='bottom'>Family</Select.Option>}
							{type === 'map' && <Select.Option value='car'>Car addon</Select.Option>}
							{type === 'map' && <Select.Option value='car-S'>Car</Select.Option>}
							{type === 'narrative' && <Select.Option value='top'>Player</Select.Option>}
							{type === 'narrative' && <Select.Option value='road'>Road</Select.Option>}
						</FormSelect>
						<FormSelect name={[...name, "S"]} style={{marginBottom:0}}>
							<Select.Option value=""></Select.Option>
							{type !== 'map' && <Select.Option value="organic">Organic</Select.Option>}
							{type !== 'map' && <Select.Option value='mechanic'>Mechanic</Select.Option>}
							{type !== 'map' && <Select.Option value='bottom'>Family</Select.Option>}
							{type === 'narrative' && <Select.Option value='top'>Player</Select.Option>}
							{type === 'map' && <Select.Option value='car'>Car addon</Select.Option>}
							{type === 'map' && <Select.Option value='car-N'>Car</Select.Option>}
							{type === 'map' && <Select.Option value='road'>Road</Select.Option>}
						</FormSelect>
						South
					</Flex>
					
					{type !=='item' && (
						<FormSelect label="East" name={[...name, "E"]}>
							<Select.Option value=""></Select.Option>
							{type === 'narrative' && <Select.Option value="left">Left Card</Select.Option>}
							{type === 'narrative' && <Select.Option value='right'>Right Card</Select.Option>}
							{type === 'map' && <Select.Option value='car'>Car addon</Select.Option>}
							{type === 'map' && <Select.Option value='car-W'>Car</Select.Option>}
						</FormSelect>
					)}
				</Flex>
			</Card>
		</Form.Item>
	</> : null
}

export default function Anchors(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
