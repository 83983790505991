import React, {useContext} from 'react'
import './Story.css'
import {FormRichText, FormSelect} from 'components/form/FormItems.js'
import String from 'components/string/String.js'
import {RenderModeContext} from 'utils/Context.js'
import { Select } from 'antd'

const RenderMode = ({data, className}) => <>
	<div className={`story ${className}`}>
		<div className="story-content">
			{data.author && <div className="story-author">{data.author}</div>}
			<String>
				{data.body}
			</String>
		</div>
	</div>
</>

const EditMode = ({name, data}) => <>
	<FormSelect label="Author" name={[...name, 'author']}>
		<Select.Option value=""> - </Select.Option>
		<Select.Option value="brick">Brick</Select.Option>
		<Select.Option value="djamal">Djamal</Select.Option>
		<Select.Option value="maeve">Maeve</Select.Option>
		<Select.Option value="selena">Séléna</Select.Option>
		<Select.Option value="swan">Swan</Select.Option>
	</FormSelect>
	<FormRichText label="Story" name={[...name, 'body']} />
</>

export default function Text(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}

