import React, {useContext} from 'react'
import './Action.css'
import Anchors from 'components/anchors/Anchors.js'
import {FormText, FormSelect, FormRichText, FormItems, FormCheckbox} from 'components/form/FormItems.js'
import String from 'components/string/String.js'
import {RenderModeContext, FileContext} from 'utils/Context.js'
import Map from 'components/card/map/Map.js'
import { Flex, Select } from 'antd'

const RenderMode = ({data, className}) => {

	const allData = useContext(FileContext)

	let passive = true
	const gridConstraint = []
	const aloneConstraint = []

	let action = data.action ? data.action : []
	action.forEach( (c) => {
		const constraint = c.constraint
		let idx = c.position - 1

		let x = c.x !== undefined ? c.x : idx % 3
		let y = c.y !== undefined ? c.y : Math.floor(idx / 3)

		passive = passive && (constraint === 'dgt' || constraint === 'start')

		// eslint-disable-next-line
		if (data.ref) gridConstraint.push({x: x, y: y, type: (constraint !==''? 'start': ''), id: c.value})
		else aloneConstraint.push(
			<div className="action-icon" key={idx}>
				<span className={`icon icon-${constraint}`}></span>
			</div>)
	})

	let grid = null

	if (data.ref) {
		// find this ref
		// eslint-disable-next-line
		let ref = allData.find(i => i.id == data.ref)

		if (ref && ref.type === 'map') {
			// clone
			ref = {...ref}
			ref.interactions = gridConstraint
			grid = <div className="map"><Map data={ref} mode="small" /></div>
		}
	}

	return <>
		<div className={`action icon-white ${passive ? 'action-passive' : ''} ${className}`}>
			{data.ref ?
				<div className="action-grid">
					{grid}
					<span className="action-ref">
						{data.ref}
					</span>
				</div>
			: aloneConstraint}
			<div className="action-content">
			<String>{data.body}</String>
			</div>

			
			{!data.anchorCard && <Anchors data={data.anchors} />}
		</div>
		{data.anchorCard && <Anchors data={data.anchors} />}
	</>
}

const EditMode = ({name, parentPath, data}) => <>
	<FormText label="Card ID" name={[...name, 'ref']} />
	<FormRichText label="Action" name={[...name, 'body']} />
	<FormItems label="Markers" name={[...name, 'action']} translatable={false} render={(itemName, options) => {
		/* eslint-disable jsx-a11y/accessible-emoji*/
		return <Flex gap="small">
			<FormSelect label={itemName===0 ? 'Type' : ''} name={[itemName, 'constraint']} style={{flex: 1, marginBottom:0}}>
				<Select.Option value="interact">✋ Interact</Select.Option>
				<Select.Option value="discuss">👄 Discuss</Select.Option>
				<Select.Option value="look">👁️ Look</Select.Option>
				<Select.Option value="start">❌ Indicator</Select.Option>
			</FormSelect>
			<FormText label={itemName===0 ? 'X' : ''} name={[itemName, 'x']} style={{flex: '0 0 50px', marginBottom: 0}}/>
			<FormText label={itemName===0 ? 'Y' : ''} name={[itemName, 'y']} style={{flex: '0 0 50px', marginBottom: 0}}/>
			{options}
		</Flex>

	}} newItem={{x: 1, y: 1, constraint: 'interact'}}/>

	<Anchors name={[...name, 'anchors']} data={data.anchors} type="narrative" />
	{data.anchors && Object.keys(data.anchors).includes('N') && <FormCheckbox name={[...name, 'anchorCard']} label="Anchors at the top and bottom of the card" /> }
</>

export default function Action(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
