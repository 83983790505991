import React, {useContext} from 'react'
import Text from './text/Text.js'
import Story from './story/Story.js'
import Action from './action/Action.js'
import Image from './image/Image.js'
import Timeline from './timeline/Timeline.js'
import NPC from './npc/NPC.js'
import './Narrative.css'
import {FormSelect, FormItems} from 'components/form/FormItems.js'
import {RenderModeContext} from 'utils/Context.js'
import { Card, Form, Select } from 'antd'

const RenderMode = ({data}) => {

	if (!data.body) return null

	let lastSectionIdx = null
	let firstSectionIdx = null

	// search for first and last item outside of images
	for (let idx = 0; idx < data.body.length; idx++) {
		if (data.body[idx].type === 'image') continue
		if (firstSectionIdx === null) firstSectionIdx = idx
		lastSectionIdx = idx
	}

	const content = data.body.map((item, idx) => {	

		let className = []
		className.push(idx === firstSectionIdx ? 'first' : 'not-first')
		className.push(idx === lastSectionIdx ? 'last' : 'not-last')

		var k = item.type
		switch (k) {
			case 'text': 
				return <Text key={idx} data={item} className={className.join(' ')}/>
			case 'timeline':
				return <Timeline key={idx} data={item} className={className.join(' ')} />
			case 'brick':
			case 'djamal':
			case 'maeve':
			case 'selena':
			case 'swan':
			case 'story': 
				return <Story key={idx} data={item} className={className.join(' ')} />
			case 'action':
				return <Action key={idx} data={item} className={className.join(' ')} />
			case 'npc':
				return <NPC key={idx} data={item} className={className.join(' ')} />
			case 'image':
				return <Image key={idx} data={item} className={className.join(' ')} />
			default: 
				return `Widget ${k} not found`
		}
	})

	return content
}

const EditMode = () => {

	const form = Form.useFormInstance()

	return (
		<FormItems label="Sections" name="body" render={(name, options) => {

			const item = form.getFieldValue(['body', name])
			let widget = null
			let title = null
			
			switch (item.type) {
				case 'text': 
					title = 'Instructions'
					widget = <Text name={[name]} parentPath={['body']} data={item} />
					break
				case 'timeline':
					title = 'Timeline'
					widget = <Timeline name={[name]} parentPath={['body']} data={item} />
					break
				case 'story': 
					title = 'Story'
					widget = <Story name={[name]} parentPath={['body']} data={item} />
					break
				case 'action':
					title = 'Action / Grid'
					widget = <Action name={[name]} parentPath={['body']} data={item} />
					break
				case 'npc':
					title = 'Character'
					widget = <NPC name={[name]} parentPath={['body']} data={item} />
					break
				case 'image':
					title = 'Image'
					widget = <Image name={[name]} parentPath={['body']} data={item} />
					break
				default:
					widget = `Widget ${item.type} not found`
			}

			return <>
				<Card type="inner" title={title} extra={options} className={`${item.type}-edit`}>
					<FormSelect label="Type" name={[name, "type"]}>
						<Select.Option value="text">Instructions</Select.Option>
						<Select.Option value="action">Action / Grid</Select.Option>
						<Select.Option value="story">Story</Select.Option>
						<Select.Option value="timeline">Timeline</Select.Option>
						<Select.Option value="npc">Character</Select.Option>
						<Select.Option value="image">Image</Select.Option>
					</FormSelect>
					{widget}
				</Card>
			</>
		}} newItem={{
			type: 'story'
		}} />
	)
}


export default function Narrative(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}