import React, {useContext} from 'react'
import './Text.css'
import Anchors from 'components/anchors/Anchors.js'
import {FormRichText, FormSelect, FormCheckbox} from 'components/form/FormItems.js'
import String from 'components/string/String.js'
import {RenderModeContext} from 'utils/Context.js'
import { Select } from 'antd'

const RenderMode = ({data, className}) => <>
	<div className={`text ${className} icon-white ${data.bodyType?'text-hasType': ''}`}>
		{data.bodyType && <div className={`text-${data.bodyType}`}></div>}
		<String className="text-content">{data.body}</String>
		{data.bodyType && !data.anchorCard &&  <Anchors data={data.anchors} />}
	</div>
	{data.bodyType && data.anchorCard && <Anchors data={data.anchors} />}
</>

const EditMode = ({name, data}) => <>
	<FormRichText label="Instructions" name={[...name, 'body']} />
	<FormSelect label='Type' name={[...name, 'bodyType']}>
		<Select.Option value=""> - </Select.Option>
		<Select.Option value="anytime">Vigilance</Select.Option>
	</FormSelect>
	{data.bodyType && <>
		<Anchors name={[...name, 'anchors']} data={data.anchors} type={'narrative'}/>
		{data.anchors && Object.keys(data.anchors).includes('N') && <FormCheckbox name={[...name, 'anchorCard']} label="Anchors at the top and bottom of the card" /> }
	</>}
</>


export default function Text(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
