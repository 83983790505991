import { IKImage } from 'imagekitio-react'
import React, {useContext} from 'react'
import {ImageContext, LangContext, RoleContext} from 'utils/Context.js'

export default function Image({transformation, style, className, name}) {

	const imageContext = useContext(ImageContext)
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	let result = name
	if (result && typeof result === 'object') {
		result = result[lang] ? result[lang] : result[role.mainLang] ? result[role.mainLang] : result.FR
	} 

	return <IKImage 
		style={style}
		className={className} 
		path={`${imageContext.path}${result}`} 
		transformation={transformation ? transformation : imageContext.transformation }
		/>
}