import React, {useContext} from 'react'
import {FormRichText} from 'components/form/FormItems.js'
import String from 'components/string/String.js'
import {RenderModeContext} from 'utils/Context.js'
import './Cache.css'

const RenderMode = ({data}) => <>
	<div className="cache-title icon-white"><String>{data.title}</String></div>
	{data.author && (<div className="cache-author"><String>{data.author}</String></div>)}
	{data.start && (<div className="cache-start icon-white"><String>{data.start}</String></div>)}
</>

const EditMode = ({data}) => <>
	<FormRichText label="Title" name="title" />
	<FormRichText label="Author" name="author" />
	<FormRichText label="Start" name="start" />
</>

export default function Cache(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}